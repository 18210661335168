import { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { resumeUpdatedAction, resumeSetToUpdateValueAction } from 'lux/models/resume';
import { ApplicantServices } from 'src/components/ApplicantServices/common';
import ResumeUpdate from 'src/components/ResumeUpdate';
import translation from 'src/components/translation';
import { humanDatesRulesType } from 'src/utils/humanDatesRules';

import ResumeRenewalButton from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeRenewalButton';
import ResumeUpdateView from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeUpdateView';

const ResumeSidebarUpdate = ({
    hash,
    humanDatesRules,
    renewal,
    setResumeUpdated,
    setResumeToUpdateValue,
    showUpdateBlock,
    timeLeft,
    isRenewalAvailable,
    trls,
    updateInterval,
}) => {
    const onUpdate = useCallback(
        (updated) => {
            setResumeUpdated({ updated });
            setResumeToUpdateValue({ value: updateInterval / 1000 });
        },
        [setResumeToUpdateValue, setResumeUpdated, updateInterval]
    );

    if (renewal) {
        return <div className="resume-sidebar-link">{trls[ResumeSidebarUpdate.trls.automatic]}</div>;
    }

    return showUpdateBlock ? (
        <Fragment>
            <ResumeUpdate
                hash={hash}
                humanDatesRules={humanDatesRules}
                onUpdate={onUpdate}
                RenderComponent={ResumeUpdateView}
                timeLeft={timeLeft}
                updateInterval={updateInterval}
                isRenewalAvailable={isRenewalAvailable}
            />
            <ResumeRenewalButton hash={hash} />
        </Fragment>
    ) : null;
};

ResumeSidebarUpdate.propTypes = {
    /** Хеш резюме */
    hash: PropTypes.string.isRequired,
    /** Правила человеческих дат, что бы это ни значило %) */
    humanDatesRules: humanDatesRulesType.isRequired,
    /** Признак того, что резюме обновляется автоматически */
    renewal: PropTypes.bool.isRequired,
    /** Установка времени последнего обновления резюме */
    setResumeUpdated: PropTypes.func.isRequired,
    /** Установка пропа toUpdate, который используется для расчета времени ближайшего поднятия */
    setResumeToUpdateValue: PropTypes.func.isRequired,
    /** Показывать блок обновления резюме */
    showUpdateBlock: PropTypes.bool.isRequired,
    /** Время до следующего обновления, мс */
    timeLeft: PropTypes.number.isRequired,
    /** Переводы */
    trls: PropTypes.object.isRequired,
    /** Минимальный интервал обновления, мс */
    updateInterval: PropTypes.number.isRequired,
    /** Доступно ли автоподнятие в данном регионе */
    isRenewalAvailable: PropTypes.bool,
};

ResumeSidebarUpdate.trls = {
    automatic: 'rb.publish.isautomatic',
};

export default connect(
    (state) => ({
        hash: state.resume?.hash,
        humanDatesRules: state.resume?.humanDatesRules,
        renewal: state.resume?.renewal,
        showUpdateBlock: state.resume?.showUpdateBlock,
        timeLeft: state.resume?.toUpdate.value * 1000,
        updateInterval: state.resume?.updateTimeout,
        isRenewalAvailable: state.applicantPaymentServices.includes(ApplicantServices.ResumeRenewal),
    }),
    {
        setResumeUpdated: resumeUpdatedAction,
        setResumeToUpdateValue: resumeSetToUpdateValueAction,
    }
)(translation(ResumeSidebarUpdate));

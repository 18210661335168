import { memo } from 'react';
import classnames from 'classnames';

import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { Gender } from 'lux/models/resume/resumeCommon.types';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    forbiddenPhoto: 'resume.forbiddenPhoto',
};

const ForbiddenPhoto: TranslatedComponent = ({ trls }) => {
    const gender = useSelector((state) => state.resume?.gender?.value || Gender.Unknown);

    return (
        <div className="resume-media" data-qa="resume-photo-forbidden">
            <HoverTip placement={TipPlacement.Top} render={() => trls[TrlKeys.forbiddenPhoto]}>
                <div className="resume-userpic resume-userpic_large">
                    <div className="resume-userpic__default-wrapper">
                        <div
                            className={classnames('resume-userpic__default', {
                                'resume-userpic__default_male': gender === Gender.Male,
                                'resume-userpic__default_female': gender === Gender.Female,
                                'resume-userpic__default_unknown': gender !== Gender.Male && gender !== Gender.Female,
                            })}
                        />
                    </div>
                </div>
            </HoverTip>
        </div>
    );
};

export default memo(translation(ForbiddenPhoto));

import { FC, PropsWithChildren } from 'react';

import { getOnlineStatus, OnlineStatus } from 'Utils/LastActivityTime';
import { Gender } from 'lux/models/resume/resumeCommon.types';
import ApplicantActivityTime from 'src/components/ApplicantActivityTime';
import { useSelector } from 'src/hooks/useSelector';

const ResumeLastActivityTime: FC<PropsWithChildren> = () => {
    const gender = useSelector((state) => state.resume?.gender.value || Gender.Male);
    const lastActivityTime = useSelector((state) => state.resume?.lastActivityTime || '');
    switch (getOnlineStatus(lastActivityTime)) {
        case OnlineStatus.Online:
            return (
                <div className="resume-online-status resume-online-status_online">
                    <ApplicantActivityTime online />
                </div>
            );

        case OnlineStatus.Offline:
            return (
                <div className="resume-online-status">
                    <ApplicantActivityTime gender={gender} lastActivityTime={lastActivityTime} />
                </div>
            );

        default:
            return null;
    }
};

export default ResumeLastActivityTime;

import { useEffect, useRef } from 'react';

import resumeKeySkillVerificationElementShown from '@hh.ru/analytics-js-events/build/xhh/common/verification_key_skill/resume_key_skill_verification_element_shown';
import resumeKeySkillVerificationHintElementShown from '@hh.ru/analytics-js-events/build/xhh/common/verification_key_skill/resume_key_skill_verification_hint_element_shown';
import { HoverTip } from 'bloko/blocks/drop';
import { TipPlacement } from 'bloko/blocks/drop/Tip';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { MonthIndex } from 'lux/models/monthIndex';
import {
    VerifiedBy,
    VerifiedSkill,
    VerifiedSkillCategory,
    VerifiedSkillLevel,
    VerifiedSkillMethod,
    VerifiedSkillResultScore,
    VerifiedSkillResultStatus,
    VerifiedSkillResultType,
} from 'lux/models/resume/resumeVerifiedSkills.types';
import { UserType } from 'lux/models/userType';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import OnboardingSkillsToolTip from 'src/components/OnbordingSkillsToolTip/OnboardingSkillsToolTip';
import useVisibleOnboardingSkillsToolTip from 'src/components/OnbordingSkillsToolTip/useVisibleOnboardingSkillsToolTip';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

export interface KeySkillsTagInnerProps {
    skillName: string;
    rank?: number;
}

const TrlKeys = {
    tips: {
        applicant: {
            [VerifiedBy.TheoryAndPractice]: 'resume.skills.verification.tip.applicant.theoryAndPractice',
            [VerifiedBy.Theory]: 'resume.skills.verification.tip.applicant.theory',
            [VerifiedBy.Practice]: 'resume.skills.verification.tip.applicant.practice',
        },
        employer: {
            [VerifiedBy.TheoryAndPractice]: 'resume.skills.verification.tip.employer.theoryAndPractice',
            [VerifiedBy.Theory]: 'resume.skills.verification.tip.employer.theory',
            [VerifiedBy.Practice]: 'resume.skills.verification.tip.employer.practice',
        },
    },
    tipConfirmedAt: 'resume.skills.verification.tip.confirmedAt',
    tipConfirmedByTest: 'resume.skills.verification.tip.confirmedByTest',
    months: {
        0: 'calendar.prepositional.month.0',
        1: 'calendar.prepositional.month.1',
        2: 'calendar.prepositional.month.2',
        3: 'calendar.prepositional.month.3',
        4: 'calendar.prepositional.month.4',
        5: 'calendar.prepositional.month.5',
        6: 'calendar.prepositional.month.6',
        7: 'calendar.prepositional.month.7',
        8: 'calendar.prepositional.month.8',
        9: 'calendar.prepositional.month.9',
        10: 'calendar.prepositional.month.10',
        11: 'calendar.prepositional.month.11',
    },
};

interface SuccessfulVerifiedSkillVerificationWithLevel {
    attemptedAt: string;
    id: number;
    level: VerifiedSkillLevel;
    method: VerifiedSkillMethod;
    result: {
        score: VerifiedSkillResultScore;
        status: VerifiedSkillResultStatus.Success;
        type: VerifiedSkillResultType;
    };
}

const getHighestSuccessfulLevel = (verifiedSkill: VerifiedSkill) => {
    const { verifications } = verifiedSkill;
    const successfulTests = verifications.filter(
        (skill) =>
            skill.result.status === VerifiedSkillResultStatus.Success &&
            skill.method !== null &&
            skill.level !== null &&
            skill.level.rank
    ) as SuccessfulVerifiedSkillVerificationWithLevel[];
    const sortedLevels = successfulTests.sort((a, b) => b.level.rank - a.level.rank);

    return sortedLevels[0];
};

const getAppropriateVerification = (verifiedSkill: VerifiedSkill, rank: number | undefined) => {
    if (rank) {
        return verifiedSkill?.verifications.find((v) => v.level?.rank === rank);
    }
    return null;
};

const getAppropriateVerificationEmployer = (verifiedSkill: VerifiedSkill) => {
    if (verifiedSkill.theory && verifiedSkill.practice) {
        return [verifiedSkill.theory, verifiedSkill.practice].sort(
            (a, b) => new Date(a.attemptedAt).getTime() - new Date(b.attemptedAt).getTime()
        )[0];
    }

    return verifiedSkill.theory || verifiedSkill.practice;
};

const KeySkillsTagInner: TranslatedComponent<KeySkillsTagInnerProps> = ({ skillName, rank, trls }) => {
    const isApplicant = useSelector((state) => state.userType) === UserType.Applicant;
    const resumeId = useSelector((state) => state.resume?.id ?? '0');
    const verifiedSkills = useSelector((state) => state.resume?.verifiedSkills);
    const isPrintVersion = useSelector((state) => state.printVersion.isPrintVersion) || false;

    const { isTipVisible, setIsTipVisible, skillVerificationTip } = useVisibleOnboardingSkillsToolTip({
        skillName,
        skillCategory: VerifiedSkillCategory.Skill,
    });

    const needSendHintAnalyticRef = useRef(true);
    const verifiedTagRef = useRef<HTMLParagraphElement>(null);

    const verifiedSkill = verifiedSkills?.find(
        (skill) => skill.name === skillName && skill.category === VerifiedSkillCategory.Skill
    );

    const isPracticeVerified = [VerifiedBy.Practice, VerifiedBy.TheoryAndPractice].includes(verifiedSkill?.verifiedBy);

    useEffect(() => {
        if (verifiedTagRef.current && resumeId && verifiedSkill) {
            resumeKeySkillVerificationElementShown(verifiedTagRef.current, {
                keySkill: skillName,
                resumeId: Number(resumeId),
                practiceVerified: isPracticeVerified,
            });
        }
    }, [isPracticeVerified, resumeId, skillName, verifiedSkill]);

    const hoverTipHandler = (isMouseOver: boolean | null) => {
        if (needSendHintAnalyticRef.current && isMouseOver && verifiedTagRef.current && resumeId) {
            resumeKeySkillVerificationHintElementShown(verifiedTagRef.current, {
                keySkill: skillName,
                resumeId: Number(resumeId),
                isOnboarding: false,
                practiceVerified: isPracticeVerified,
            });
            needSendHintAnalyticRef.current = false;
        }
    };

    if (!verifiedSkill || isPrintVersion) {
        return <AsyncHighlighter>{skillName}</AsyncHighlighter>;
    }

    const appropriateVerification = isApplicant
        ? getAppropriateVerification(verifiedSkill, rank)
        : getAppropriateVerificationEmployer(verifiedSkill);
    const appropriateAttempt = appropriateVerification ?? getHighestSuccessfulLevel(verifiedSkill);
    if (rank && appropriateVerification?.result.status !== VerifiedSkillResultStatus.Success) {
        return <AsyncHighlighter>{skillName}</AsyncHighlighter>;
    }
    const attemptedAt = appropriateAttempt ? new Date(appropriateAttempt.attemptedAt) : '';
    const attemptedYear = attemptedAt ? attemptedAt.getFullYear() : '';
    const attemptedMonthIndex = attemptedAt ? (attemptedAt?.getMonth() as MonthIndex) : '';
    const attemptedMonth = attemptedMonthIndex !== '' ? trls[TrlKeys.months[attemptedMonthIndex]] : '';

    const attemptedAtText =
        attemptedMonth && attemptedYear
            ? format(trls[TrlKeys.tipConfirmedAt], {
                  '{0}': `${attemptedMonth} ${attemptedYear}`,
              })
            : '';

    const content = (
        <span ref={verifiedTagRef}>
            {' '}
            <AsyncHighlighter>{skillName}</AsyncHighlighter>
        </span>
    );

    if (isTipVisible) {
        return (
            <OnboardingSkillsToolTip
                attemptedAtText={attemptedAtText}
                methodName={skillName}
                setIsTipVisible={setIsTipVisible}
                skillVerificationTip={skillVerificationTip}
                keySkill={skillName}
                resumeId={resumeId}
                practiceVerified={isPracticeVerified}
            >
                {content}
            </OnboardingSkillsToolTip>
        );
    }

    return (
        <HoverTip
            placement={TipPlacement.Bottom}
            host={!process.env.LUX_SERVER ? document.body : null}
            onHover={hoverTipHandler}
            render={() =>
                verifiedSkill.verifiedBy !== VerifiedBy.None ? (
                    <p>
                        {format(trls[TrlKeys.tips[isApplicant ? 'applicant' : 'employer'][verifiedSkill.verifiedBy]], {
                            '{0}': `${attemptedMonth} ${attemptedYear}`,
                        })}
                    </p>
                ) : null
            }
        >
            {content}
        </HoverTip>
    );
};
export default translation(KeySkillsTagInner);

import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { VendorAnalytics } from '@hh.ru/analytics-js';
import resumeNoAnswerButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_no_answer_button_click';
import resumeNotArrivedButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_not_arrived_button_click';
import resumeTopicActionButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_topic_action_button_click';
import { HSpacing, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import MenuItem from 'bloko/blocks/drop/Menu/Item';
import urlParser from 'bloko/common/urlParser';

import { HHTM_SOURCE_VACANCY_RESPONSES } from 'Modules/constants/analytics';
import { TransitionType } from 'lux/models/employerNegotiations/negotiationLinks';
import { Platform } from 'lux/models/locale.types';
import { Permission } from 'lux/models/resume/resumeCommon.types';
import { UserType } from 'lux/models/userType';
import AdaptiveButtons from 'src/components/AdaptiveButtons';
import ChangeTopicButton from 'src/components/ChangeTopicButton';
import ChangeTopicMenuItem from 'src/components/ChangeTopicMenuItem';
import NoCallAnswer from 'src/components/Employer/NoCallAnswer';
import NonAppearanceTrigger from 'src/components/Employer/NonAppearance';
import PfpBillingButton from 'src/components/PfpBillingButton';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useSelector } from 'src/hooks/useSelector';

import OpenContacts from 'src/pages/ResumeView/components/ResumeHeader/Contacts/OpenContacts';
import ResumeVideocallInviteButton from 'src/pages/ResumeView/components/ResumeVideocallIniveButton';

export const sendAnalytics = () => {
    VendorAnalytics.eventToStorage('contact_gift', 'invite', 'recommended', '');
};

const LINKS_PROPS = {
    [TransitionType.ChangeTopic]: {
        [`data-qa`]: 'resume__change-topic-button',
        kind: ButtonKind.Primary,
    },
    [TransitionType.Invite]: {
        [`data-qa`]: 'resume__invite-button',
        kind: ButtonKind.Success,
    },
    [TransitionType.Discard]: {
        [`data-qa`]: 'resume__discard-button',
    },
    [TransitionType.Consider]: {
        [`data-qa`]: 'resume__postpone-button',
    },
    [TransitionType.InviteAnother]: {
        [`data-qa`]: 'resume__another-vacancy-button',
    },
};

const LINKS_ORDER = [
    TransitionType.ChangeTopic,
    TransitionType.Invite,
    TransitionType.Discard,
    TransitionType.Consider,
    TransitionType.InviteAnother,
];

export const renderActionButtons = (
    { links, negotiationLinks, negotiationsCount, vacancyId, resumeId, isDiscardStatusesExp },
    renderItem,
    noCallAnswerButton,
    nonAppearanceButton,
    videoCall
) => {
    let negotiationButtons;

    if (isDiscardStatusesExp) {
        negotiationButtons = LINKS_ORDER.reduce((buttons, transitionType) => {
            const linkProps = LINKS_PROPS[transitionType];
            const location = negotiationLinks[transitionType];
            const isDiscard = transitionType === TransitionType.Discard;
            const isInviteAnother = transitionType === TransitionType.InviteAnother;
            const isInvite = transitionType === TransitionType.Invite;
            const onClick =
                isInviteAnother || (negotiationsCount === 0 && isInvite)
                    ? sendAnalytics
                    : (toState) =>
                          resumeTopicActionButtonClick({ vacancyId, resumeId, topicAction: transitionType, toState });

            if (location) {
                buttons.push(renderItem({ location, transitionType, isDiscard, onClick, props: { ...linkProps } }));
            }

            return buttons;
        }, []);
    } else {
        negotiationButtons = links.map(({ location, transitionType }) => {
            if (!LINKS_PROPS[transitionType]) {
                return null;
            }

            return renderItem({
                location,
                transitionType,
                props: {
                    ...LINKS_PROPS[transitionType],
                    onClick:
                        transitionType === TransitionType.InviteAnother ||
                        (negotiationsCount === 0 && transitionType === TransitionType.Invite)
                            ? sendAnalytics
                            : () => resumeTopicActionButtonClick({ vacancyId, resumeId, topicAction: transitionType }),
                },
            });
        });
    }

    if (videoCall && negotiationButtons.length) {
        negotiationButtons.splice(1, 0, videoCall);
    }
    if (noCallAnswerButton) {
        negotiationButtons = negotiationButtons.concat(noCallAnswerButton);
    }
    if (nonAppearanceButton) {
        negotiationButtons = negotiationButtons.concat(nonAppearanceButton);
    }
    return negotiationButtons;
};

const ResumeTransitions = ({
    userType,
    vacancyId,
    topicId,
    resumeId,
    resumeHash,
    firstName,
    fromResponsesPage,
    lastName,
    links,
    negotiationsCount,
    permission,
    nonAppearanceEnabled,
    nonCallAnswerEnabled,
    isHH,
    isCrossposted,
    query,
    trls,
    hasPfpTopics,
    isPfpAvailable,
    applicantId,
    discardUrl,
}) => {
    const { isXS } = useBreakpoint();
    const canBuyContacts = useSelector((state) => state.resume.resumeContactsOpening?.canOpenContactsThroughCIV);
    const additionalCheck = useSelector(({ additionalCheck }) => additionalCheck);
    const isVideoCallsDisabled = useFeatureEnabled('video_calls_disabled');
    const isDiscardStatusesExp = useSelector((state) => state.isDiscardStatusesExp);
    const negotiationLinks = useSelector((state) => state.employerNegotiationLinks);

    const isContactsOpened = permission !== Permission.ViewWithoutContacts;
    const showPfpButtons = isPfpAvailable && hasPfpTopics && !isContactsOpened;

    if (
        userType !== UserType.Employer ||
        (isContactsOpened && !(Array.isArray(links) && links.length !== 0)) ||
        (!isContactsOpened && hasPfpTopics && !isPfpAvailable) ||
        (isCrossposted && !(fromResponsesPage && query.t !== undefined)) ||
        (isDiscardStatusesExp && !negotiationLinks)
    ) {
        return null;
    }

    if (!isContactsOpened) {
        const billingButtonProps = {
            isAccent: true,
            topicId,
            resumeId,
            applicantId,
            resumeHash,
            onContactsBought: () => {
                window.location.reload();
            },
        };

        const handleSendAnalytics = (toState) => {
            resumeTopicActionButtonClick({
                vacancyId,
                resumeId,
                topicAction: TransitionType.Discard,
                toState,
            });
        };

        if (showPfpButtons && additionalCheck) {
            return null;
        }

        return (
            <>
                {showPfpButtons ? (
                    <AdaptiveButtons
                        buttons={[
                            <PfpBillingButton key="pfpBillingButton" {...billingButtonProps} />,
                            <HSpacing key="hspacing" default={12} />,
                            isDiscardStatusesExp && negotiationLinks.discard ? (
                                <ChangeTopicButton
                                    links={negotiationLinks.discard}
                                    showIcon={!isXS}
                                    sendAnalytics={handleSendAnalytics}
                                />
                            ) : (
                                <Button
                                    key="discard"
                                    Element={Link}
                                    to={discardUrl}
                                    onClick={() => handleSendAnalytics()}
                                >
                                    {trls[ResumeTransitions.trls.discard]}
                                </Button>
                            ),
                        ]}
                        menuItems={() => [
                            <PfpBillingButton key="pfpBillingButton" type="link" {...billingButtonProps} />,
                            <HSpacing key="hspacing" default={12} />,
                            isDiscardStatusesExp && negotiationLinks.discard ? (
                                negotiationLinks.discard.map(({ name, url }) => (
                                    <ChangeTopicMenuItem
                                        key={name}
                                        name={name}
                                        url={url}
                                        sendAnalytics={handleSendAnalytics}
                                    />
                                ))
                            ) : (
                                <MenuItem key="discard" href={discardUrl} onClick={() => handleSendAnalytics()}>
                                    {trls[ResumeTransitions.trls.discard]}
                                </MenuItem>
                            ),
                        ]}
                    />
                ) : (
                    <OpenContacts />
                )}
                {(showPfpButtons || canBuyContacts) && <VSpacing default={28} />}
            </>
        );
    }

    if (additionalCheck) {
        return null;
    }

    return isCrossposted && !(fromResponsesPage && query.t !== undefined) ? null : (
        <div className="resume-actions">
            <AdaptiveButtons
                buttons={renderActionButtons(
                    { links, negotiationLinks, negotiationsCount, vacancyId, resumeId, isDiscardStatusesExp },
                    ({ location, transitionType, isDiscard, onClick, props }) => (
                        <Fragment key={transitionType}>
                            {isDiscard && (
                                <ChangeTopicButton links={location} showIcon={!isXS} sendAnalytics={onClick} />
                            )}
                            {!isDiscard && (
                                <Button to={location} Element={Link} onClick={onClick} {...props}>
                                    {trls[ResumeTransitions.trls[transitionType]]}
                                </Button>
                            )}
                            <HSpacing default={12} />
                        </Fragment>
                    ),
                    nonCallAnswerEnabled ? (
                        <Fragment key="nocallanswer">
                            <NoCallAnswer
                                viewType="button"
                                topicId={topicId}
                                onClick={() => resumeNoAnswerButtonClick({ resumeId, vacancyId })}
                            />
                            <HSpacing default={12} />
                        </Fragment>
                    ) : null,
                    nonAppearanceEnabled ? (
                        <NonAppearanceTrigger
                            key="nonappearance"
                            vacancyId={vacancyId}
                            resumeHash={resumeHash}
                            data-qa="non-appearance__trigger"
                            onSubmit={() => resumeNotArrivedButtonClick({ resumeId, topicId })}
                        />
                    ) : null,
                    isHH && !isVideoCallsDisabled ? (
                        <ResumeVideocallInviteButton
                            applicant={{
                                resumeHash,
                                name: firstName,
                                lastName,
                            }}
                        />
                    ) : null
                )}
                menuItems={({ onClose }) =>
                    renderActionButtons(
                        {
                            links,
                            negotiationLinks,
                            negotiationsCount,
                            vacancyId,
                            resumeId,
                            isDiscardStatusesExp,
                        },
                        ({ location, transitionType, isDiscard, onClick, props }) => {
                            if (isDiscard) {
                                return location.map(({ name, url }) => (
                                    <ChangeTopicMenuItem key={name} name={name} url={url} sendAnalytics={onClick} />
                                ));
                            }
                            return (
                                <MenuItem
                                    to={location}
                                    Element={Link}
                                    key={transitionType}
                                    onClick={onClick}
                                    {...props}
                                >
                                    {trls[ResumeTransitions.trls[transitionType]]}
                                </MenuItem>
                            );
                        },
                        nonCallAnswerEnabled ? (
                            <NoCallAnswer
                                onClick={() => {
                                    onClose?.();
                                    resumeNoAnswerButtonClick({ resumeId, vacancyId });
                                }}
                                key="nocallanswer"
                                viewType="menu"
                                topicId={topicId}
                            />
                        ) : null,
                        nonAppearanceEnabled ? (
                            <NonAppearanceTrigger
                                key="nonappearance"
                                vacancyId={vacancyId}
                                resumeHash={resumeHash}
                                viewType="menu"
                                data-qa="non-appearance__trigger"
                                onSubmit={() => resumeNotArrivedButtonClick({ resumeId, topicId })}
                            />
                        ) : null,
                        isHH && !isVideoCallsDisabled ? (
                            <ResumeVideocallInviteButton
                                applicant={{
                                    resumeHash,
                                    name: firstName,
                                    lastName,
                                }}
                                isMenuItem
                            />
                        ) : null
                    )
                }
            />
            <VSpacing default={28} />
        </div>
    );
};

ResumeTransitions.propTypes = {
    trls: PropTypes.object,
    userType: PropTypes.string,
    vacancyId: PropTypes.string,
    topicId: PropTypes.string,
    resumeHash: PropTypes.string,
    resumeId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    permission: PropTypes.string,
    negotiationsCount: PropTypes.number,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            location: PropTypes.string,
            transitionType: PropTypes.string,
        })
    ),
    isHeadHunterPlatform: PropTypes.bool,
    nonAppearanceEnabled: PropTypes.bool,
    nonCallAnswerEnabled: PropTypes.bool,
    isHH: PropTypes.bool,
    isCrossposted: PropTypes.bool,
    query: PropTypes.object,
    fromResponsesPage: PropTypes.bool,
    isExperimentEnabled: PropTypes.func,
    isPfpAvailable: PropTypes.bool,
    hasPfpTopics: PropTypes.bool,
    applicantId: PropTypes.string,
    discardUrl: PropTypes.string,
};

ResumeTransitions.defaultProps = {
    links: [],
};

ResumeTransitions.trls = {
    inviteAnother: 'resume.button.inviteToAnotherVacancy',
    invite: 'vacancyResponse.invite',
    changeTopic: 'resume.button.changeTopic',
    discard: 'vacancyResponse.discard',
    consider: 'resume.button.consider',
    considerLong: 'resume.button.movetoConsider',
};

ResumeTransitions.features = {
    videoCallsDisabled: 'video_calls_disabled',
};

export default connect((state) => {
    const isPfpAvailable = state.pfpInfo?.pfpStatus === 'ENABLED';
    const { params } = urlParser(state.request.url);
    const { hash, firstName, lastName, hasPfpTopics, user, discardUrl } = state.resume;
    const topicId = params.t?.[0];
    const vacancyId = params.vacancyId?.[0];
    return {
        vacancyId,
        userType: state.userType,
        permission: state.resume?.permission,
        resumeId: state.resume?.id,
        resumeHash: hash,
        links: hash && state.employerResumesNegotiationLinks?.[hash],
        negotiationsCount: state.employerNegotiations.total,
        nonAppearanceEnabled: !!vacancyId,
        topicId,
        isHH: state.locale.platform === Platform.HeadHunter,
        firstName: firstName?.value,
        lastName: lastName?.value,
        fromResponsesPage: state.analyticsParams.hhtmFrom === HHTM_SOURCE_VACANCY_RESPONSES,
        query: state.router.location.query,
        isCrossposted:
            state.resume?.sitePlatform === Platform.Zarplata && state.locale.platform === Platform.HeadHunter,
        nonCallAnswerEnabled: !!topicId,
        hasPfpTopics,
        isPfpAvailable,
        applicantId: user,
        discardUrl,
    };
})(translation(ResumeTransitions));

import { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { ChatikContext } from '@hh.ru/chatik-integration';
import Button, { ButtonIconPosition, ButtonAppearance } from 'bloko/blocks/button';
import { BubbleScaleSmallKindSingleAppearanceOutlinedShapeRound } from 'bloko/blocks/icon';
import urlParser from 'bloko/common/urlParser';

import { selectorCanCommunicateWithApplicant } from 'src/components/HiringManager/utils/selectorCanCommunicateWithApplicant';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const ResumeContactsChatButton = ({ trls }) => {
    const openChatik = useContext(ChatikContext)?.openChatik;
    const chatButtonRef = useRef();
    const chatikIntegration = useSelector((state) => state.chatikIntegration);
    const location = useSelector((state) => state.router.location);
    const vacancyId = urlParser(location.search)?.params?.vacancyId?.[0];
    const resumeId = urlParser(location.search)?.params?.resumeId?.[0];
    const topicList = useSelector((state) => state.employerNegotiations.topicList);
    const canUseChat = useSelector(selectorCanCommunicateWithApplicant);

    const chatId =
        topicList && topicList.length
            ? topicList?.find((item) => item.vacancyId.toString() === vacancyId)?.chatId
            : null;

    useEffect(() => {
        if (chatButtonRef.current) {
            Analytics.sendHHEventElementShown(chatButtonRef.current, {
                name: 'chat_entry_resume_contacts_show',
                vacancyId,
                resumeId,
            });
        }
    }, [chatButtonRef, resumeId, vacancyId]);

    if (!canUseChat || !chatId || !chatikIntegration) {
        return null;
    }

    const handleClick = () => {
        openChatik?.({
            chatId,
            view: 'widget',
            hhtmFromLabel: 'resume',
        });
        Analytics.sendHHEventButtonClick('chat_entry_resume_contacts_click', { vacancyId, resumeId });
    };

    return (
        <div ref={chatButtonRef}>
            <Button
                onClick={handleClick}
                icon={<BubbleScaleSmallKindSingleAppearanceOutlinedShapeRound />}
                iconPosition={ButtonIconPosition.Right}
                appearance={ButtonAppearance.Outlined}
            >
                {trls[ResumeContactsChatButton.trls.text]}
            </Button>
        </div>
    );
};

ResumeContactsChatButton.trls = {
    text: 'chatik.activator.resume',
};

ResumeContactsChatButton.propTypes = {
    trls: PropTypes.object,
};

export default translation(ResumeContactsChatButton);

import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import { UserType } from 'lux/models/userType';
import ResumeChatPinnedMessages from 'src/components/ResumeView/ResumeChatPinnedMessages';
import WebcallButton from 'src/components/WebcallButton';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import ResumeEmptyBlock from 'src/pages/ResumeView/components/View/ResumeEmptyBlock';
import { PHONES } from 'src/utils/constants/resumeHiddenFields';
import { VIEW_WITHOUT_CONTACTS } from 'src/utils/constants/resumePermission';

import ResumeContactsChatButton from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContactsChat';
import ResumeContactsEmail from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContactsEmail';
import ResumeContactsPersonalSites from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContactsPersonalSites';
import ResumeContactsPhones from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContactsPhones';
import ShowContacts from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ShowContacts';

const ResumeContacts = ({ isAllowed, isEmpty, isPhoneHidden, openedContacts, phone, userType, trls }) => {
    const [contactsVisibility, setContactsVisibility] = useState(
        userType !== UserType.Employer || isPhoneHidden || !phone || openedContacts
    );
    const pinnedChatMessages = useSelector((state) => state.pinnedChatMessages?.items);

    if (isEmpty) {
        return (
            <div className="resume-header-field">
                <ResumeEmptyBlock block="contacts" />
            </div>
        );
    }

    if (isAllowed) {
        return (
            <div className="resume-header-field">
                <div data-qa="resume-block-contacts">
                    <Text importance={TextImportance.Tertiary}>{trls[ResumeContacts.trls.contacts]}</Text>
                    <VSpacing base={1} />
                    {contactsVisibility ? (
                        <>
                            <ResumeContactsPhones />
                            <ResumeContactsEmail />
                            <ResumeContactsPersonalSites />
                        </>
                    ) : (
                        <ShowContacts phone={phone} setContactsVisibility={setContactsVisibility} />
                    )}
                    <ResumeEditLink block="contacts" data-qa="resume-block-contacts-edit" WrapperComponent="p" />
                    <VSpacing base={4} />
                    <div className="resume-communication-buttons noprint">
                        <WebcallButton />
                        <ResumeContactsChatButton />
                    </div>
                    <VSpacing base={1} />
                    {pinnedChatMessages && <ResumeChatPinnedMessages pinnedChatMessages={pinnedChatMessages} />}
                </div>
            </div>
        );
    }

    return null;
};

ResumeContacts.trls = {
    contacts: 'resume.field.contacts',
};

ResumeContacts.propTypes = {
    isAllowed: PropTypes.bool.isRequired,
    isEmpty: PropTypes.bool,
    isPhoneHidden: PropTypes.bool.isRequired,
    openedContacts: PropTypes.bool,
    phone: PropTypes.shape({
        city: PropTypes.string,
        comment: PropTypes.string,
        country: PropTypes.string,
        formatted: PropTypes.string,
        number: PropTypes.string,
        raw: PropTypes.string,
        type: PropTypes.string,
        verified: PropTypes.bool,
        needVerification: PropTypes.bool,
    }),
    userType: PropTypes.string.isRequired,
    trls: PropTypes.object.isRequired,
};

export default connect((state) => ({
    isAllowed: state.resume?.permission !== VIEW_WITHOUT_CONTACTS,
    isEmpty: state.resume?.emptyBlocks.includes('contacts'),
    isPhoneHidden: state.resume?.hiddenFields.includes(PHONES),
    openedContacts: state.resume?.openedContacts,
    phone: state.resume?.phone.value?.[0],
    userType: state.userType,
}))(translation(ResumeContacts));

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { OK } from 'http-status-codes';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';

import markContactsAsShown from 'Modules/Resume/Contacts/markContactsAsShown.obfuscate';
import { UserType } from 'lux/models/userType';
import { VIEW } from 'src/utils/constants/resumePermission';

const loadContacts = (WrappedComponent) => {
    class hoc extends PureComponent {
        validate = (event, goal) => {
            const { canViewContacts, openedContacts, resumeId, resumeHash, isEmployer, vacancyId } = this.props;

            if (!isEmployer) {
                return Promise.resolve();
            }

            if (!canViewContacts) {
                return Promise.resolve();
            }

            if (openedContacts) {
                Analytics.sendHHEvent('resumeShowContacts', {
                    goal,
                    resumeId,
                    vacancyId,
                });
                return Promise.resolve();
            }

            return new Promise((resolve, reject) => {
                event.persist();
                markContactsAsShown({
                    event,
                    resumeId,
                    resumeHash,
                    goal,
                }).then(
                    ({ status }) => {
                        if (status === OK) {
                            Analytics.sendHHEvent('resumeShowContacts', {
                                goal,
                                resumeId,
                                vacancyId,
                            });
                            resolve();
                        } else {
                            reject();
                        }
                    },
                    () => {
                        reject();
                    }
                );
            });
        };

        render() {
            const { openedContacts, resumeHash, resumeId, ...props } = this.props;

            return <WrappedComponent {...props} validate={this.validate} />;
        }
    }

    hoc.propTypes = {
        isEmployer: PropTypes.bool,
        WrappedComponent: PropTypes.func,
        canViewContacts: PropTypes.bool,
        openedContacts: PropTypes.bool,
        resumeId: PropTypes.string,
        resumeHash: PropTypes.string,
        goal: PropTypes.string,
        vacancyId: PropTypes.string,
    };

    return connect((state) => ({
        isEmployer: state.userType === UserType.Employer,
        location: state.router.location,
        canViewContacts: state.resume?.permission === VIEW,
        openedContacts: state.resume?.openedContacts,
        resumeId: state.resume?.id,
        resumeHash: state.resume?.hash,
        vacancyId: state.router.location.query?.vacancyId,
    }))(hoc);
};

export default loadContacts;

import { FC, PropsWithChildren } from 'react';

import { isHhTariff } from 'lux/models/price/hhTariff';
import { TariffProductType, DBAccessProductType } from 'lux/models/price/product.types';
import { useSelector } from 'src/hooks/useSelector';

import BuyContactsResumeAccess from 'src/components/ResumeContacts/BuyContacts/BuyContactsResumeAccess';
import BuyContactsResumeHasSim from 'src/components/ResumeContacts/BuyContacts/BuyContactsResumeHasSim';
import BuyContactsZp from 'src/components/ResumeContacts/BuyContacts/BuyContactsZp';

const BuyContacts: FC<PropsWithChildren> = () => {
    const productList = useSelector(({ snippetAccessResume }) => snippetAccessResume.productList);
    const hasSim = useSelector(({ snippetAccessResume }) => snippetAccessResume.sim?.has);
    if (hasSim) {
        return <BuyContactsResumeHasSim />;
    }

    const currentProduct = productList?.[0];

    if (!currentProduct) {
        return null;
    }

    if (currentProduct.tariff && !isHhTariff(currentProduct.tariff)) {
        return <BuyContactsZp currentProduct={currentProduct as TariffProductType} />;
    }

    return <BuyContactsResumeAccess currentProduct={currentProduct as DBAccessProductType} />;
};

export default BuyContacts;

import { Fragment, useCallback, useMemo } from 'react';
import { DefaultRootState, useDispatch } from 'react-redux';

import resumeHistoryButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_history_button_click';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { UserType } from 'lux/models/userType';
import { useNotification } from 'src/components/Notifications/Provider';
import resumeSidebarLoadError from 'src/components/Notifications/ResumeSidebar';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';
import fetcher from 'src/utils/fetcher';

import Item from 'src/pages/ResumeView/components/View/ResumeSidebar/History/HistoryItem';

const MAX_EVENTS = 3;
const RESUME_HISTORY_URL = '/shards/resume/history';
const load = makeSetStoreField('negotiationsHistory');

type QueryParams = {
    resumeHash?: string;
    vacancyId?: string;
    maxEvents?: number;
};

declare global {
    interface FetcherGetApi {
        [RESUME_HISTORY_URL]: {
            queryParams: QueryParams;
            response: { vacancies: DefaultRootState['negotiationsHistory'] };
        };
    }
}

const TrlKeys = {
    expand: 'resume.employer.history.expand',
    collapse: 'resume.employer.history.collapse',
};

const History: TranslatedComponent = ({ trls, visible = true }) => {
    const [expanded, toggleExpanded] = useToggleState(false);
    const dispatch = useDispatch();
    const resumeId = useSelector((state) => state.resume?.id);
    const { location, userType, negotiationsHistory, resumeHash } = useSelector(
        ({ router, resume, negotiationsHistory, userType }) => ({
            location: router.location,
            negotiationsHistory,
            userType,
            resumeHash: resume?.hash,
        })
    );
    const params = useMemo<QueryParams>(() => ({ resumeHash }), [resumeHash]);
    const { addNotification } = useNotification();

    if (userType === UserType.BackOffice && location.query.vacancyId) {
        params.vacancyId = location.query.vacancyId;
    }
    const fetchHistoryAndHideButton = useCallback(() => {
        resumeHistoryButtonClick({ vacancyId: location.query.vacancyId, resumeId });
        fetcher
            .get(RESUME_HISTORY_URL, { params })
            .then((result) => {
                dispatch(load(result.vacancies));
                toggleExpanded();
            })
            .catch(() => {
                addNotification(resumeSidebarLoadError);
            });
    }, [location.query.vacancyId, resumeId, params, dispatch, toggleExpanded, addNotification]);

    if (!visible || !negotiationsHistory) {
        return null;
    }

    let vacancies = negotiationsHistory;
    if (!expanded) {
        vacancies = vacancies.slice(0, MAX_EVENTS);
    }

    return (
        <Fragment>
            {vacancies.map((vacancy) => (
                <Item vacancy={vacancy} key={vacancy['@vacancyId']} />
            ))}
            {!expanded && (
                <div className="resume-sidebar-item">
                    <Link
                        appearance={LinkAppearance.Pseudo}
                        kind={LinkKind.Tertiary}
                        onClick={fetchHistoryAndHideButton}
                        data-qa="resume-history-toggle"
                    >
                        {trls[TrlKeys.expand]}
                    </Link>
                </div>
            )}
        </Fragment>
    );
};

export default translation(History);

import { VSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import { H3 } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { UserType } from 'lux/models/userType';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import styles from './index.less';

const TrlKeys = {
    candidateForVacancy: 'resume.sidebar.hiringManager.candidateForVacancy',
};

const CandidateForVacancy: TranslatedComponent = ({ trls }) => {
    const { t } = useSelector((state) => state.router.location.query);
    const isEmployer = useSelector((state) => state.userType === UserType.Employer);
    const employerNegotiations = useSelector((state) => state.employerNegotiations || { topicList: [] });
    const vacancyName =
        isEmployer && employerNegotiations.topicList.find((topic) => topic.id.toString() === t)?.vacancyName;
    const isHiringManager = useSelector((state) => state.isHiringManager);
    const hasSharedVacancy = useSelector((state) => !!state.hiringManagerVacancyPermission);
    const { mSidebar } = useGetMColumnSize();

    if (!(!hasSharedVacancy && isEmployer && isHiringManager && vacancyName)) {
        return null;
    }

    return (
        <Column xs="4" s="8" m={mSidebar} l="4">
            <VSpacing default={16} />
            <div className={styles.hiringManagerBlock}>
                <H3>{trls[TrlKeys.candidateForVacancy]}</H3>
                <VSpacing default={4} />
                <Text strong>{vacancyName}</Text>
            </div>
            <VSpacing default={24} />
        </Column>
    );
};

export default translation(CandidateForVacancy);
